import React, {useState, useEffect} from 'react';
import Loader from '../components/Loader';
import {BASE_API, BASE_URL,fetchGet} from "../Utils/Constants";

const EvoPayment = (props) => {

    const [ cart, SetCart ]= useState([]);
    const [ order, SetOrder ]= useState("");
    const [ notificationMessage, SetNotificationMessage ] = useState("");
    const [ showMessage, SetShowMessage ]= useState(false);
    const [loading, setLoadind] = useState(false);
    const orderNumber = Math.floor(Math.random() * 1000) + 1;


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    const paymentSuccess = e => {
       
        fetchGet('updateOrderStatus/'+order.id)
        .then(response => { 
            window.location.href = '/pago/'+ order.id;
        }).catch(error =>{
            console.log(error);
        });
    }

    const banamexPay = () => {
        window.Checkout.showLightbox();
    }

    useEffect( () => {
        let path = ''
        if (props.match.params.id !== undefined) {
          path += '/' + props.match.params.id;
        }
        setLoadind(true);
        fetchGet('getDataPayment' + path)
        .then(data => {
            if(data.error){
                SetShowMessage(true);
                SetNotificationMessage(data.message);
                setLoadind(false);
            }else{
                if(data.order != undefined){
                    if(data.order.order_status != 1){
                        SetCart(data.products);
                        SetOrder(data.order);
            
                        const dataProducts = data.products.map(item => ({name:item.name, quantity: item.quantity, unitPrice:item.unit_price}));
                        evo(dataProducts,data.order);    

                        SetNotificationMessage("");
                        SetShowMessage(false);
                    }else{
                        SetShowMessage(true);
                        SetNotificationMessage("Orden pagada...");
                    }
                    setLoadind(false);
                }else{
                    setLoadind(false);
                    SetShowMessage(true);
                    SetNotificationMessage("Orden no encontrada....");
                }
            }

        }).catch(error => {    
            setLoadind(false);        
            console.log("ERROR PRODUCTS", error);
        });

    },[]);

    const evo = (itemsProducts,orderData) => {

        let url = `${BASE_API}get/session/${orderNumber}`;

        let xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.responseType = 'json';
        xhr.onload = function() {
            if(xhr.readyState === 4) {
                if(xhr.status != 200) { console.error(xhr.response); return; }
                configureCheckoutPayLoad(xhr.response, itemsProducts, orderData);
            }
        };
        xhr.send();
    }

    function configureCheckoutPayLoad(session, itemsProducts, orderData)
    {
        window.Checkout.configure({
            merchant: session.merchant,
            session: {
                id: session['session.id']
            },
            order: {
                amount: Number(orderData.total),
                currency: 'MXN',
                description: 'Productos ordenados',
                id: orderNumber,
                item: itemsProducts,
                itemAmount: Number(orderData.subtotal),
                shippingAndHandlingAmount: Number(orderData.shipping_cost)
            },
            interaction: {
                operation: 'PURCHASE', // set this field to 'PURCHASE' for Hosted Checkout to perform a Pay Operation.
                displayControl: {
                    billingAddress: 'HIDE',
                    orderSummary: 'SHOW',
                    shipping: 'READ_ONLY'
                },
                merchant: {
                    name: 'HECHOS LA RAYA',
                    address: {
                        line1: 'Calle Piña 1724, Local 12-C',
                        line2: 'Mercado de Abastos, Guadalajara, Jalisco.'
                    }
                }
            }
        });
    }

    const decodeFormParams = params => {
        const pairs = params.split('&'),
          result = {};
    
        for (let i = 0; i < pairs.length; i++) {
          let pair = pairs[i].split('='),
            key = decodeURIComponent(pair[0]),
            value = decodeURIComponent(pair[1]),
            isArray = /\[\]$/.test(key),
            dictMatch = key.match(/^(.+)\[([^\]]+)\]$/);
    
          if (dictMatch) {
            key = dictMatch[1];
            const subkey = dictMatch[2];
    
            result[key] = result[key] || {};
            result[key][subkey] = value;
          } else if (isArray) {
            key = key.substring(0, key.length-2);
            result[key] = result[key] || [];
            result[key].push(value);
          } else {
            result[key] = value;
          }
        }
    
        return result;
    }



    const showMessageInfo = () => {
        return (
            <div className="row" > 
                    <div className="cart-back table-responsive" >
                        <p>{notificationMessage}</p>
                    </div>
            </div>
        );
    }


    const showTableInfo = () => {
        return (
            <>
                { loading ?   <Loader property={loading} /> : <>
                <div className="row">
                    <div className="cart-back table-responsive">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th className="th">Imagen</th>
                                <th className="th">Producto</th>
                                <th className="th">Cantidad</th>
                                <th className="th">Precio</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                cart.map(product => {
                                    return (
                                        <tr key={product.product_id}>
                                        <td align="center"><img className="img-responsive" style={{height:40}} src={BASE_URL + product.image} alt=""/></td>
                                        <td align="center">{product.name}</td>
                                        <td align="center">{product.quantity + ' ' + product.unit} </td>
                                        <td align="center">{formatter.format(product.price)} </td>
                                        </tr>
                                    )
                                })
                            }

                            </tbody>
                        </table>
                        <div className='row' style={{float:'right', paddingTop:10, paddingRight:10}}>
                            <p>Subtotal: {formatter.format(order.subtotal)}</p>
                            <p>Costo de envío: {formatter.format(order.shipping_cost)}</p>
                            <p>Total: {formatter.format(Number(order.total) )}</p>

                            <div className='col-md-3'>
                                <div className="cartBtn2">
                                <button type="button" className="btn btnTheme" onClick={() => banamexPay()}> Pagar </button>
                                <input type='hidden' id='payment' onClick={paymentSuccess}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </>
                }
            </>
        );
    }

    return (
        <section className="naturalInner">
            <div className="container">
                <div className="row">
                    <div className="headingOther">
                        <h2>PRODUCTOS EN LA<span> ORDEN</span></h2>
                    </div>
                </div>

                {showMessage ? showMessageInfo() : showTableInfo()}
            </div>
        </section>
    );
};

export default EvoPayment;